<style lang="scss">
$width800: 800px;
.native {
  [class*='col-'] {
  }
  .themen-box {
    padding: 15px;
    .bild-box {
      padding: 15px 0;
    }
    h4 {
      font-size: 120%;
      text-align: center;
      margin-bottom: 29px;
    }
    .content-link-modal {
      -webkit-transition: all 500ms ease;
      cursor: pointer;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      display: block;
      text-align: center;
      padding: 10px;
      background: $blue;
      color: #fff;
      margin-bottom: 45px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .vorteile {
    margin: 20px 0;
    text-align: center;
    .nr {
      margin-bottom: 10px;
      text-align: center;
      display: block;
      position: relative;
      i {
        position: absolute;
        left: 0;
        top: 0;
        color: $blue-darker;
        font-size: 60px;
        z-index: 1;
        left: 50%;
        margin-left: -29px;
        top: 23px;
        @media (max-width: $width-lg) {
        }
      }
      @media (max-width: $width-lg) {
        display: block;
      }
      span {
        display: inline-block;
        color: #fff;
        position: relative;
        z-index: 2;
        line-height: 50px;
        width: 50px;
        font-size: 100%;
      }
    }
    h5 {
      text-align: center;
      display: inline-block;
      font-weight: 300;
      padding: 0 15px;
      @media (max-width: $width-md) {
        margin-bottom: 20px;
      }
      @media (max-width: 600px) {
        display: block;
        margin-left: 0;
        text-align: center;
      }
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .native-ads-block {
    .col-md-4 {
      padding: 2%;
      .native-ad-numbers {
        text-align: center;
        @media (max-width: 768px) {
          margin-bottom: 50px;
          border: 0px solid blue;
        }
        span {
          font-size: 2em;
          font-family: $KleineTitel-Bold;
          background: $blue-darker;
          color: #fff;
          padding: 20px 0px;
          max-width: 200px;
          display: block;
          margin: 0 auto;
        }
        .headline-keywords {
          font-size: 1.5em;
          text-transform: uppercase;
          color: $blue-darker;
          font-family: $KleineTitel-Bold;
          margin-bottom: 20px;
          // font-family: $font-family-roboto-slap;
        }
        p {
          text-align: center;
          line-height: 1.8;
        }
        .native-ad-img {
          height: 180px;
          margin-bottom: 30px;
          position: relative;
          transform: scale(1);
          overflow: hidden;
          transition: transform 125ms linear;
          &:after {
            content: '';
            display: block;
            width: 50px;
            height: 5px;
            background: #ddd;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            margin-left: -25px;
            bottom: 0;
            display: none;
          }
          img {
            display: block;
            margin: 20px auto;
            width: 100px;
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
        }
        &:hover {
          .native-ad-img {
            // transform: scale(1.1);
          }
        }
      }
    }
  }
}
.info-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 120%;
  padding: 0 50px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    padding: 0 10px;
  }
}
.btn-big {
  display: block;
  margin: 10px 0;
  text-align: center;
  margin-top: 30px;
  a {
    display: inline-block;
    padding: 15px 20px;
    background: $blue;
    color: #fff !important;
    &:hover {
      opacity: 0.9;
    }
  }
}

.erfolgbsp {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    display: block;

    position: relative;
    span {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      background-color: rgba($color: #fff, $alpha: 0.8);
    }
  }
}

.tab-group-container {
  .title.active {
  }
  .title-container {
    @media (max-width: 768px) {
      display: block !important;
    }
  }
  .title {
    padding: 15px 20px;
    .small {
      @media (max-width: 1370px) and (min-width: 1200px) {
        display: block;
      }
    }
    @media (max-width: 768px) {
      display: block;
      width: 100%;

      text-align: center;
    }
  }
  .inner-content {
    .headline {
      margin-top: 40px;
    }
    .vorteile {
      margin-bottom: 40px;
      .col-lg-4 {
        justify-content: flex-start !important;
        align-self: flex-start !important;
      }
    }
    .leistung {
      &:before {
        content: '';
        display: block;
        margin: 20px 0;
        width: 150px;
        height: 5px;
        background: $blue-darker;
      }
      &:after {
        content: '';
        display: block;
        margin: 20px 0;
        width: 100px;
        height: 5px;
        background: $blue-darker;
      }
    }
  }
  .breaker {
    display: block;
    height: 1px;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .highlight-txt {
    background: $blue;
    padding: 15px;
    color: #fff;
    a {
      color: $blue-darker !important;
    }
  }
}
</style>

<template>
  <div class="content native container">
    <h1>Erzählen Sie Ihre Geschichte!</h1>
    <p class="intro-txt">
      Unsere Expertinnen und Experten in Sachen Content verpacken Ihre Werbebotschaften im Stil redaktioneller Berichterstattung - beratend, informativ und unterhaltsam aufbereitet. Diese „natürlich“ anmutenden, aber werblich gekennzeichneten Inhalte bieten für Leserinnen und Leser einen echten Mehrwert. Sie wirken auf der emotionalen Ebene und bleiben dadurch länger im Gedächtnis.
    </p>
    <p>Vom maßgeschneiderten PR-Text für Ihr Unternehmen, Angebot oder Produkt bis hin zum professionellen Storytelling in Form einer Sponsored Story haben wir für Ihren persönlichen Werbebedarf die richtige Lösung: <strong>glaubwürdig, nachhaltig, wirksam</strong>.</p>

    <div class="margin-t-s">
      <h2>Ihre Werbemöglichkeiten im Bereich Native</h2>
    </div>
    <TabGroup>
      <Tab tabid="prpdodukt-tab1" :title="'Sponsored Story'" active>
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Sponsored Story</h2>
            </div>
            <div class="col-lg-12 align-self-center">
              <p>
                Sponsored Stories sind redaktionelle Artikel mit Kundenintegration. Die Inhalte sind authentisch geschrieben und als Werbung gekennzeichnet. Anders als bei Advertorials steht das Thema und nicht das Unternehmen / Produkt / Angebot im Vordergrund. Werbekunden werden als Experten zu einem bestimmten Thema positioniert. Die Story wird über mehrere Kanäle u.a. kleinezeitung.at, App und
                Facebook Post ausgespielt.
              </p>
            </div>
          </div>
          <!--<div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>lange Verweildauer durch Storytelling</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>aktivierend und glaubwürdig</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>bleibt lange in Erinnerung und <span class="breaker"></span> wirkt nachhaltig</h5>
            </div>
          </div>
          -->
          <div class="row native-ads-block justify-content-center padding-tb-s">
            <div class="col-md-4 col-10">
              <div class="native-ad-numbers">
                <span class="shadow-lg">84%</span>
                <div class="native-ad-img">
                  <img src="@/assets/img/img-content/digital/native-ad-icon-herz.svg" alt="Glaubwürdig" class="img-fluid" />
                </div>
                <div class="headline-keywords">Glaubwürdig</div>
                <p>84 Prozent der Leser empfinden die gelesenen Inhalte als stimmig und vertrauensvoll.</p>
              </div>
            </div>
            <div class="col-md-4 col-10">
              <div class="native-ad-numbers">
                <span class="shadow-lg">4/5</span>
                <div class="native-ad-img">
                  <img src="@/assets/img/img-content/digital/native-ad-icon-nachhaltig.svg" alt="Nachhaltig" class="img-fluid" />
                </div>
                <div class="headline-keywords">Nachhaltig</div>
                <p>Mehr als 4/5 der Leser erinnern sich an mindestens ein Detail des Ads.</p>
              </div>
            </div>
            <div class="col-md-4 col-10">
              <div class="native-ad-numbers">
                <span class="shadow-lg">2:30</span>
                <div class="native-ad-img">
                  <img src="@/assets/img/img-content/digital/native-ad-icon-wirksam.svg" alt="Wirksam" class="img-fluid" />
                </div>
                <div class="headline-keywords">Wirksam</div>
                <p>Im Durchschnitt setzen sie sich mit dem Inhalt rund 2:30 Minuten auseinander.</p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Ausführliches Briefing und Projektmanagement</li>
                <li>Content Produktion im redaktionellen Look &amp; Feel <small>(zwei Korrekturschleifen inklusive)</small></li>
                <li>Bewerbung auf kleinezeitung.at, auf der Kleinen Zeitung-APP und in den sozialen Netzwerken</li>
                <li>Reporting der relevanten Kennzahlen</li>
                <li>Inkl. einmalige Integration im Kleine Zeitung-Mittags-Newsletter (für Stmk und Ktn)</li>
                <li>Vorlaufzeit: 5 Werktage</li>
              </ul>
              <br />

              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">3.000 Leserinnen und Lesern</div>
                  <div class="col-lg-6 col-4 text-align-right">4.985,00</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">1.500 Leserinnen und Lesern</div>
                  <div class="col-lg-6 col-4 text-align-right">3.450,00</div>
                </div>
              </div>

              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/#erfolgsbeispiele" target="_blank">Entdecken Sie hier unsere Erfolgsbeispiele </a>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/digital/smartphone-sponsored-story.png" alt="Sponsored Story" class="img-fluid img-s-60 img-center" />
            </div>
          </div>

          <div class="info-txt-preis margin-b-s">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer. Produktionskosten sind nicht rabattfähig.</div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab2" :title="'Advertorial'">
        <div class="inner-content">
          <div class="row justify-content-center align-self-center">
            <div class="col-lg-12">
              <h2>Advertorial</h2>
            </div>
            <div class="col-lg-12">
              <p>Ein Advertorial unterscheidet sich kaum von einem redaktionellen Artikel und dient der gezielten Bewerbung einer Sache (Unternehmen, Angebot, Produkt). Auf diese Weise wird die Werbung nicht als störend empfunden. Damit Ihr Advertorial auch von der gewünschten Zielgruppe gefunden und gelesen wird, sorgen wir für reichlich Aufmerksamkeit.</p>
            </div>
            <div class="col-lg-6 align-self-center">
              <p>
                Das Native Ad und das Perfomance-Upgrade verlinken bei Klick auf den Artikel, welcher vom Kunden selbst angeliefert wird (kurzer PR-Text). Das Advertorial dient als Landing Page. Umsetzung mit Bildergalerien, Videos und Factboxes sind möglich. Die werblichen Inhalte fügen sich optimal in die Inhalte der Website oder App ein, ohne den Lesefluss des Users zu unterbrechen. All das
                führt zu einer höheren Akzeptanz beim User.
              </p>
            </div>
            <div class="col-lg-6 align-self-center">
              <ul class="content-ul">
                <li>Hohe Nutzerakzeptanz</li>
                <li>Positive Markenwahrnehmung</li>
                <li>Nachhaltiger Branding-Effekt (Kundenbindung)</li>
              </ul>
            </div>
          </div>
          <!--<div class="headline">Vorteile</div>
          <div class="row vorteile">
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>1</span></div>
              <h5>Unternehmen/Produkt steht im Vordergrund</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>2</span></div>
              <h5>maßgeschneiderter<span class="breaker"></span> PR-Text</h5>
            </div>
            <div class="col-lg-4">
              <div class="nr"><i class="material-icons">grade</i><span>3</span></div>
              <h5>auffällig und reichweitenstark</h5>
            </div>
          </div>-->
          <div class="row">
            <div class="col-lg-6">
              <br />
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Content-Produktion inkl. Bildersuche</li>
                <li>Korrektorat (Zwei Korrekturschleifen)</li>
                <li>Anlage des Artikels auf kleinezeitung.at</li>
                <li>Vorlaufzeit 5 Werktage</li>
              </ul>

              <!--<p><small>Damit Ihr Text bei der gewünschten Zielgruppe ankommt, empfehlen wir einen Content Teaser mit mind. 10.000 Sichtkontakten.</small></p>-->
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">* Produktion</div>
                  <div class="col-lg-6 col-4 text-align-right">290,00</div>
                </div>
                <!--<div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Mindestbuchungsvolumen</div>
                  <div class="col-lg-6 col-4 text-align-right">500,00*</div>
                </div>
                <div class="info-txt-tabelle">Der TKP ist abhängig vom Buchungsvolumen. Bei einem Buchungsvolumen von 500 Euro beträgt der TKP maximal 19,50 und reduziert sich bei höherem Kampagnenbudget entsprechend.</div>-->
                <div class="info-txt-tabelle">* Produktionskosten sind nicht rabattfähig</div>
                <div class="info-txt-tabelle">
                  Nur in Kombination mit einem Native Ad (Mindesbuchungsvolumen: 500,00 EURO) oder Performance Upgrade buchbar
                </div>
              </div>

              <div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/#erfolgsbeispiele" target="_blank">Entdecken Sie hier unsere Erfolgsbeispiele </a>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/digital/Devices-Advertorial.png" alt="Advertorial" class="img-fluid" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab3" :title="'Performance Upgrade '">
        <h2>Leistung &amp; Tarif</h2>
        <div class="row">
          <div class="col-lg-6">
            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">je 1.000 Klicks</div>
                <div class="col-lg-6 col-4 text-align-right">2.800,00</div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="prpdodukt-tab4" :title="'Themenspecial'">
        <div class="inner-content">
          <div class="row">
            <div class="col-lg-6">
              <br />
              <h2>Leistungen &amp; Tarif</h2>
              <ul class="content-ul">
                <li>Max. 10 Artikel</li>
                <li>Mindesbuchung: 6 Artikel</li>
                <li>Content-Produktion, Bildersuche, Korrektorat und Anlage der Artikel</li>
                <li>Einrichtung der Übersichtsseite sowie eigenen Subdomain auf kleinezeitung.at*</li>
                <li>Vorlaufzeit: 10 Werktage</li>
              </ul>
              <!--<p><small>Damit Ihr Text bei der gewünschten Zielgruppe ankommt, empfehlen wir einen Content Teaser mit mind. 10.000 Sichtkontakten.</small></p>-->
              <div class="tabelle-box">
                <div class="row head-row ">
                  <div class="col-lg-8 text-align-left hide-small"></div>
                  <div class="col-lg-4 text-align-right hide-small">Tarif</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-6 col-8 text-align-left ">Pro Artikel</div>
                  <div class="col-lg-6 col-4 text-align-right">290,00</div>
                </div>
                <div class="info-txt-tabelle">* Produktionskosten sind nicht rabattfähig</div>
                <div class="info-txt-tabelle">Nur in Kombination mit einem Native Ad buchbar (Mindestbuchungsvolumen: 500,00 Euro)</div>
              </div>

              <!--<div class="btn-std btn-bg-blue-dark margin-t-s text-center">
                <a href="https://www.kleinezeitung.at/layout/klz/files/dossiers/native/#erfolgsbeispiele" target="_blank">Entdecken Sie hier unsere Erfolgsbeispiele </a>
              </div>-->
            </div>
            <div class="col-lg-6">
              <img src="@/assets/img/img-content/digital/Devices-Themenspecial-1.png" alt="Themenspecial" class="img-fluid img-s-75 img-center" />
            </div>
          </div>

          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% Umsatzsteuer.</div>
        </div>
      </Tab>
    </TabGroup>
    <!--<div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/prprodukte/DIGITALE-PR-Produkte-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif PR-Produkte Digital
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>799 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  components: {
    TabGroup,
    Tab,
  },
});
</script>
